import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import UsersPage from '../../admin/users/UsersPage';
import messages from './UsersPage.messages';
import { Tab, TabGroup } from '@planview/pv-uikit';
import { AppContext, AppContextProps, DovetailContext } from '../../../context';
import { color, spacingPx } from '@planview/pv-utilities';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorBoundary from '../../../components/common/ErrorBoundary';
import DovetailComponent, {
	AdditionalDovetailProps,
	getDovetailLocale,
} from '../../../components/common/DovetailComponent';
import UserContext from '../../../context/userContext';
import { DovetailLocale } from '../../../types/dovetail/dovetail';

export enum UserTabs {
	CURRENT_USERS,
	DEACTIVATED_USERS,
	GROUPS,
}

const StyledTabGroup = styled(TabGroup)`
	background-color: #ffffff;
`;

const TabBody = styled.div`
	flex-grow: 1;
	min-height: 0;
	padding-top: ${spacingPx.xsmall};
	display: flex;
	flex-direction: column;
`;

const GroupsComponentContainer = styled.div`
	flex-grow: 1;
	background-color: ${color.gray0};
	width: 100%;
	max-width: 100%;
`;

const tabMap: { [key: string]: UserTabs } = {
	current: UserTabs.CURRENT_USERS,
	deactivated: UserTabs.DEACTIVATED_USERS,
	groups: UserTabs.GROUPS,
};

const loadTabs = ({ featureFlags }: AppContextProps) => {
	const { enableDeactivateUsers, enableGroups } = featureFlags;
	const tabs = [UserTabs.CURRENT_USERS];
	if (enableDeactivateUsers) {
		tabs.push(UserTabs.DEACTIVATED_USERS);
	}

	if (enableGroups) {
		tabs.push(UserTabs.GROUPS);
	}

	return tabs;
};

type UsersTabPageParams = {
	tab: string;
};

const UsersTabsPage = () => {
	const { tab = '' } = useParams<UsersTabPageParams>();
	const tabIndex = tabMap[tab];
	const { language: lang } = navigator;
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const { tenantGroupMap } = appContext;
	const { user } = useContext(UserContext);
	const { id: user_id } = user;
	const { dovetailEnvironment: environment, dovetailUrl } =
		useContext(DovetailContext);
	const intl = useIntl();

	const availableTabs = useMemo(() => loadTabs(appContext), [appContext]);
	const currentTab = availableTabs[tabIndex];

	const labels: { [tab: string]: () => string } = {
		[UserTabs.CURRENT_USERS]: () =>
			intl.formatMessage(messages.currentUsersTab),
		[UserTabs.DEACTIVATED_USERS]: () =>
			intl.formatMessage(messages.deactivatedUsersTab),
		[UserTabs.GROUPS]: () => intl.formatMessage(messages.groupsTab),
	};

	// TODO: We'll use primary tenant group id for now - might need a selector on the Groups tab to handle sandboxes
	const tenantGroups = [...tenantGroupMap.values()];
	const tenantGroup = tenantGroups.find(({ sandbox }) => !sandbox);
	const dovetailProps: AdditionalDovetailProps = useMemo(() => {
		return {
			environment,
			user_id,
			user_locale: {
				date_format: 'MM/DD/YYYY',
				locale: getDovetailLocale(lang) as DovetailLocale,
				start_of_week: 0,
			},
		};
	}, [environment, user_id, lang]);

	const changeTab = (newTab: UserTabs) => {
		let tabName;
		switch (newTab) {
			case UserTabs.DEACTIVATED_USERS:
				tabName = 'deactivated';
				break;
			case UserTabs.GROUPS:
				tabName = 'groups';
				break;
			default:
				tabName = 'current';
		}
		navigate(`/admin/users/${tabName}`, { state: { redirect: true } });
	};

	return (
		<React.Fragment>
			{availableTabs.length > 1 && (
				<StyledTabGroup defaultIndex={tabIndex}>
					{availableTabs.map((tabType) => (
						<Tab
							key={tabType}
							onActivate={() => changeTab(tabType)}
							label={labels[tabType]()}
						/>
					))}
				</StyledTabGroup>
			)}
			{currentTab === UserTabs.CURRENT_USERS && (
				<TabBody>
					<UsersPage isCustomerCare={false} mode={currentTab} />
				</TabBody>
			)}
			{currentTab === UserTabs.DEACTIVATED_USERS && (
				<TabBody>
					<UsersPage isCustomerCare={false} mode={currentTab} />
				</TabBody>
			)}
			{currentTab === UserTabs.GROUPS && (
				<TabBody>
					<GroupsComponentContainer>
						<ErrorBoundary>
							<DovetailComponent
								name="Groups"
								type="groups"
								element="div"
								url={`${dovetailUrl}/dist/Groups.min.js`}
								tenantGroupId={tenantGroup?.tenantGroupId ?? ''}
								props={dovetailProps}
							/>
						</ErrorBoundary>
					</GroupsComponentContainer>
				</TabBody>
			)}
		</React.Fragment>
	);
};

export default UsersTabsPage;
