import React, { useState } from 'react';
import AppContext, { AppContextProps, defaultAppContext } from './appContext';
import UserContext, {
	defaultUserContext,
	UserContextProps,
} from './userContext';
import DovetailContext, {
	defaultDovetailContext,
	DovetailContextProps,
} from './dovetailContext';
import { get, logError, LOGIN_API_URL } from '../hooks/request/request';
import { compare } from 'compare-versions';
import { Application, EnvSelector, TenantGroup, User } from '../types';
import { useIdleTimer } from 'react-idle-timer';
import { useIntl } from 'react-intl';
import messages from './GlobalState.messages';
import { Toast } from '../types/toast';
import { ApiResponse, LoginResponse } from '../types/api/api';
import { getApplicationsById } from '../helpers/util';

// 30sec
const APP_DATA_REFRESH = 30_000;

type BaseGlobalStateProps = DovetailContextProps &
	AppContextProps &
	UserContextProps;

interface GlobalStateProps extends BaseGlobalStateProps {
	children?: React.ReactNode;
}

const initialState: GlobalStateProps = {
	...defaultDovetailContext,
	...defaultAppContext,
	...defaultUserContext,
};

let lastResponseText = '';
/**
 * Whether login polling is enabled
 * @type {boolean}
 */
let loginPollingEnabled: boolean = true;

/**
 * Abort controller for current polling of login data
 * @type {AbortController}
 */
let pollingController: AbortController = new AbortController();

/**
 * Timer ID for next polling of login data
 */
let timer: NodeJS.Timeout | undefined;

const getUserFullName = (user: User) => {
	return `${user.firstName} ${user.lastName}`;
};

const hasPassword = (user: User) => {
	const { authenticationType } = user;
	return authenticationType === 'PASSWORD';
};

// Adds custom attributes on the user object
const getUserData = (user: User): User => {
	return {
		...user,
		// custom attributes for the user
		name: getUserFullName(user), // Name is not a readily available attribute on the user object
		passwordUser: hasPassword(user), // SSO or Forms-based auth user flag
	};
};

// determine if the response has changed from the last request
const hasResponseChanged = (response: LoginResponse & ApiResponse) => {
	const responseText = JSON.stringify(response);
	if (responseText !== lastResponseText) {
		lastResponseText = responseText;
		return true;
	}
	return false;
};

/*
 * On OverviewPage we only want to show tiles that can be clicked to trigger login.
 * Apps where username is null, when ssoEnabled is false, or are hidden need to be removed.
 * (username is null only for customer admins that don't have an account in the product.
 */
export function getOverviewApplications(applications: Application[]) {
	return applications.filter(
		(product) =>
			product.custom ||
			(product.ssoEnabled &&
				product.username &&
				!product.hideFromOverview),
	);
}

const GlobalState = (props: GlobalStateProps) => {
	const [stateInternal, setStateInternal] =
		useState<GlobalStateProps>(initialState);
	const [needsLogin, setNeedsLogin] = useState<boolean>(false);
	const [toast, setToast] = useState<Toast>();
	const intl = useIntl();

	// this gives us the "current" state always in the state variable
	let state = stateInternal;
	const setState = (newState: GlobalStateProps) => {
		state = newState;
		setStateInternal(newState);
	};

	const handleOnActive = () => {
		// if there's not already a refresh timer, then start polling
		if (!timer) {
			scheduleRefreshAppData();
		}
	};

	const idleTimer = useIdleTimer({
		timeout: 1000 * 60, // 1 min
		debounce: 500,
		onActive: handleOnActive,
	});

	const setLoginPollingEnabled = (enabled: boolean) => {
		loginPollingEnabled = enabled;
		if (enabled) {
			scheduleRefreshAppData();
		} else {
			pollingController.abort();
			clearTimeout(timer);
		}
	};

	const addApplications = (initialApplications: Application[]) => {
		// Primary label is shown when there are both primary and non-primary of the same app & sandbox type.
		// e.g. E1 having primary and non-primary sandbox
		const appsWithNonPrimary = initialApplications.filter(
			(app) => app.primary === false,
		);
		const showPrimaryLabel = (application: Application) => {
			if (application.primary && application.sandbox !== null) {
				return appsWithNonPrimary.some(
					(app) =>
						app.envSelector.application ===
							application.envSelector.application &&
						app.sandbox === application.sandbox,
				);
			}
			return false;
		};

		// augment 'initialApplications' with additional data to create 'applications'
		const applications = initialApplications.map((application) => {
			const envSelector = new EnvSelector(
				application.envSelector.application,
				application.envSelector.tenantId,
			);
			return {
				...application,
				envSelector,
				showPrimaryLabel: showPrimaryLabel(application),
				// envSelectorEncodedString is URI encoded for Safari, needed for any URL
				envSelectorEncodedString: encodeURIComponent(
					envSelector.toString(),
				),
				// envSelectorString is used internally when building filters, etc
				envSelectorString: envSelector.toString(),
				app: application.envSelector.application,
			} as Application;
		});

		// Create 'applicationsById'
		const applicationsById = getApplicationsById(applications);
		const overviewApplications = getOverviewApplications(applications);

		// Create 'tenantGroupMap
		// todo mike 2024-09-13 server return TenantGroups from UserContext and use that in client global state
		const tenantGroupMap = getTenantGroupMap(applications);

		setState({
			...state,
			applications,
			applicationsById,
			overviewApplications,
			tenantGroupMap,
		});
	};

	const getTenantGroupMap = (applications: Application[]) => {
		const tenantGroupMap = new Map<string, TenantGroup>();

		applications.forEach((app) => {
			const { sandbox, primary, tenantGroupId } = app;
			const title = !sandbox
				? intl.formatMessage(messages.production)
				: primary
					? intl.formatMessage(messages.sandbox)
					: app.title;

			const tenantGroup = {
				tenantGroupId,
				sandbox,
				primary,
				title,
			};
			tenantGroupMap.set(tenantGroupId, tenantGroup);
		});

		return tenantGroupMap;
	};

	const processUserData = (loginData: LoginResponse) => {
		const { customer, user, impersonator, orgHierarchy } = loginData;
		const {
			isAdmin = false,
			isCustomerCare = false,
			isSuperAdmin = false,
		} = user;

		const _user = getUserData(user);

		// store the text of the last response, for hasResponseChanged()
		if (!lastResponseText) {
			lastResponseText = JSON.stringify(loginData);
		}

		setState({
			...state,
			isAuthenticated: true,
			user: _user,
			isAdmin,
			isCustomerCare,
			isSuperAdmin,
			customer,
			impersonator,
			orgHierarchy,
		});
	};

	const processAppData = (loginData: LoginResponse) => {
		// featureFlags are defined in com.planview.shauth.common.flags.FeatureFlag.java
		// and set in dev in feature-flags.properties
		const {
			applications,
			availableApplications,
			dovetailUrl,
			environment,
			notificationBackendUrl,
			featureFlags,
			region,
			availableRegions = [],
		} = loginData;

		let dovetailEnvironment;
		switch (environment) {
			case 'LOCAL':
			case 'DEVELOPMENT':
				dovetailEnvironment = 'dev';
				break;
			case 'STAGING':
				dovetailEnvironment = 'stg';
				break;
			default:
				dovetailEnvironment = region?.toLowerCase();
				if (dovetailEnvironment === 'us') {
					dovetailEnvironment = 'pd';
				}
				break;
		}

		addApplications(applications);

		setState({
			...state,
			availableApplications,
			environment,
			dovetailEnvironment,
			dovetailUrl,
			notificationBackendUrl,
			region,
			availableRegions,
			featureFlags,
		});
	};

	const showToast = (toast: Toast) => {
		setToast({
			...toast,
			id: Math.floor(Math.random() * 1001 + 1),
		});
	};

	const clearUserContext = () => {
		setState({
			...state,
			...defaultUserContext,
		});
	};

	const hasImpersonator = () => {
		return Boolean(state.impersonator);
	};

	// The user needs to have a "isCustomerCare" flag
	const hasCustomerCareAccess = () => {
		return state.isCustomerCare;
	};

	// The user needs to have a "isSuperAdmin" flag
	const hasSuperAdminAccess = () => {
		return state.isSuperAdmin;
	};

	// The current viewed customer. Planview customer will have an ID starting with 'PLANVIEW'.
	// Planview customer is an internal only customer that houses Pv Admins and Super Admins only.
	const isPvCustomer = (customerId?: string) => {
		const customerIdToCheck = customerId || state.customer.id;
		return customerIdToCheck.startsWith('PLANVIEW');
	};

	// 'true' if the current logged-in user is customer care (not a super admin)
	// and is viewing (admin) screens for Planview Customer.
	const isCustomerCareViewingPvCustomer = (customerId?: string) => {
		return (
			hasCustomerCareAccess() &&
			!hasSuperAdminAccess() &&
			isPvCustomer(customerId)
		);
	};

	// 'true' if the current logged-in user is a Super Admin and is viewing (admin) screens for Planview Customer.
	const isSuperAdminViewingPvCustomer = (customerId?: string) => {
		return hasSuperAdminAccess() && isPvCustomer(customerId);
	};

	// Check the current version of the app vs. the version returned in the response header and set the new version and 'forceRefresh' flag as needed.
	const checkAppVersion = (newVersion?: string) => {
		const { appDetails } = state;

		// newVersion could be null or "Unknown" (default from server)
		if (!newVersion || newVersion === 'Unknown') {
			return;
		}
		// version will be null when we first load the app. Set it to the known value from the first request
		if (!appDetails.version) {
			setState({
				...state,
				appDetails: {
					version: newVersion,
					forceRefresh: false,
				},
			});
		} else if (compare(newVersion, appDetails.version, '>')) {
			setState({
				...state,
				appDetails: {
					version: newVersion,
					forceRefresh: true,
				},
			});
		}
	};

	const isIdle = () => {
		return idleTimer && idleTimer.isIdle();
	};

	const scheduleRefreshAppData = () => {
		if (loginPollingEnabled) {
			timer = setTimeout(() => {
				refreshAppData();
			}, APP_DATA_REFRESH);
		}
	};

	// Application and user data is polled peridically
	const refreshAppData = () => {
		clearTimeout(timer);

		if (loginPollingEnabled && state.isAuthenticated && !isIdle()) {
			refreshAppDataAsync()
				.then(() => {
					loginPollingEnabled && scheduleRefreshAppData();
				})
				.catch((error: Error) => {
					void logError(
						'Error scheduling refresh of app data',
						error,
					);
				});
		}
	};

	const refreshAppDataAsync = async () => {
		pollingController.abort();
		pollingController = new AbortController();

		const response = (await get(LOGIN_API_URL, {
			signal: pollingController.signal,
		})) as LoginResponse & ApiResponse;
		checkAppVersion(response._appVersion);
		if (
			loginPollingEnabled &&
			response &&
			response.success &&
			hasResponseChanged(response)
		) {
			processUserData(response);
			processAppData(response);
		} else if (response.httpStatusCode === 401) {
			setNeedsLogin(true);
		}
	};

	return (
		<AppContext.Provider
			value={{
				appDetails: state.appDetails,
				applications: state.applications,
				applicationsById: state.applicationsById,
				availableApplications: state.availableApplications,
				availableRegions: state.availableRegions,
				environment: state.environment,
				featureFlags: state.featureFlags,
				needsLogin,
				overviewApplications: state.overviewApplications,
				region: state.region,
				tenantGroupMap: state.tenantGroupMap,
				toast,
				showToast,
				addApplications,
				processAppData,
				checkAppVersion,
				scheduleRefreshAppData,
				setNeedsLogin,
				refreshAppData,
				setLoginPollingEnabled,
				idle: isIdle(),
			}}
		>
			<UserContext.Provider
				value={{
					user: state.user,
					customer: state.customer,
					impersonator: state.impersonator,
					orgHierarchy: state.orgHierarchy,
					isAuthenticated: state.isAuthenticated,
					isAdmin: state.isAdmin,
					isCustomerCare: state.isCustomerCare,
					isSuperAdmin: state.isSuperAdmin,
					processUserData,
					clearUserContext,
					hasImpersonator,
					hasCustomerCareAccess: hasCustomerCareAccess,
					hasSuperAdminAccess,
					isPvCustomer,
					isCustomerCareViewingPvCustomer,
					isSuperAdminViewingPvCustomer,
					getUserFullName,
					hasPassword,
				}}
			>
				<DovetailContext.Provider
					value={{
						dovetailEnvironment: state.dovetailEnvironment,
						dovetailUrl: state.dovetailUrl,
						notificationBackendUrl: state.notificationBackendUrl,
					}}
				>
					{props.children}
				</DovetailContext.Provider>
			</UserContext.Provider>
		</AppContext.Provider>
	);
};

export default GlobalState;
